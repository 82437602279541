import http from "../http";
import axios from "axios";


/**
 * 合并已有采购订单(采购请求)
 */
export const mergePurchaseOrder = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/mergePurchaseRequestToPurchaseOrder`,
    data
  });
};



/**
 * 关闭该条订单(采购订单)
 */
export const closeTransferOrder = (purchaseOrderId) => {
  return http({
    method: "get",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/closePurchaseOrder?purchaseOrderId=${purchaseOrderId}`,
  });
};


/**
 * 关闭该条订单（预收货清单）
 */
export const closeTransferOrderAdvance = (id) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/closeDocuments?id=${id}`,
  });
};

/**
 * 分页查询采购计划结果表
 */
export const purchasePlanList= (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SALEPREDICT_URL + `salepredict/v1/readyPlanResults/searchByPage?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 获取采购计划展示列列表
 */
export const purchaseshowlist = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/readyPlanResults/columns`
  });
};


/**
 * 删除采购计划管理信息
 */
export const purchasedelete = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseplans/${id}`,
  });
};


/**
 * 新增采购计划管理信息获取uuid
 */
export const purchasegetuuid = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/getUUID`,
  });
};


/**
 * 新增采购计划管理信息
 */
export const purchaseadd = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequests`,
    data
  });
};

/**
 * 修改采购计划管理信息
 */
export const purchaseupdmessage = (id, data) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseplans/${id}`,
    data
  });
};





/**
 * 修改采购请求子项展示的信息
 */
export const purchasechildshow = (purchaseRequestCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems/selectPrItemsByPrCode?purchaseRequestCode=${purchaseRequestCode}`
  });
};


/**
 * 添加采购请求子项的信息
 */
export const purchasechildadd = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems`,
    data
  });
};


/**
 * 删除采购请求子项的信息
 */
export const purchasechilddel = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems/${id}`
  });
};

/**
 * 执行采购——采购请求
 * 分页查询采购申请单(申请+采购项)
 */
export const purchaseRequestList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseRequestAndItems/searchItemByConditionForOrder?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——采购请求
 * 分页查询采购订单（订单 + 订单项）
 */
export const purchaseOrderList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorderAndItems/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——采购请求
 * 根据id查询采购申请项
 */
export const searchPurchaseRequestItem = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems/${id}`
  });
};

/**
 * 执行采购——采购请求
 * 新增采购订单同时添加订单子项
 */
export const savePurchaseOrder = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorders/savePurchaseOrder`,
    data
  });
};

/**
 * 执行采购——采购请求
 * 根据id查询采购订单和订单项
 */
export const searchPurchaseOrderList = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorderanditems/${id}`
  });
};

/**
 * 执行采购——采购请求
 * 删除采购订单的一行
 */
export const deletePurchaseOrder = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorders/${id}`,
  });
};
/**
 * 删除一行
 */
export const deletePurchaseOrderItem = (id) => {
  return http({
    method: "delete",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/removeOrderItemsById/${id}`,
  });
};
/**
 * 执行采购——采购请求
 * 批量修改采购订单子项
 */
export const modifyPurchaseOrder = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/updatepoanditembetch`,
    data
  });
};

/**
 * 执行采购——货妥管理
 * 货妥列表
 */
export const cargoReadyList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/searchOrderWithPageForCargoReady?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——货妥管理
 * 根据订单id查询货妥清单
 */
export const purchaseGoodsList = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/queryReadyListByFactory?factoryConfirmUuid=${uuid}`
  });
};

/**
 * 执行采购——货妥管理
 * 添加货妥清单
 */
export const addPurchaseGoodsList = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/saveReadList`,
    data
  });
};

/**
 * 执行采购——货妥管理
 * 根据货妥清单id逻辑删除货妥清单,并同步采购订单项的货妥信息
 */
export const deletePurchaseGoodsList = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/removeReadList/${id}`,
  });
};

/**
 * 执行采购——采购订单
 * 批量修改采购订单
 */
export const submitModifyPurchaseOrder = (data) => {
  return http({
    // method: "put",
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorders/batch`,
    data
  });
};

/**
 * 执行采购——采购订单
 * 查询价单名称
 */
// export const getPriceOrderList = (supplierCode, currency, factorySeason) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_SCM_URL + `purchasepricelist/v1/queryPriceListName?supplierCode=${supplierCode}&currency=${currency}&factorySeason=${factorySeason}`,
//   });
// };
export const getPriceOrderList1 = (supplierCode, currency, factorySeason) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: 'supplierNo', opr: 'eq', value: supplierCode },
      { fieldName: 'currency', opr: 'eq', value: currency.toString() },
      { fieldName: 'factorySeason', opr: 'eq', value: factorySeason }
    ],
    subTotal: [],
    groupBy: []
  }
  data.query = data.query.filter(item=>item.value!='')
  let result = http({
    method: "post",
    url: process.env.VUE_APP_URL + `finance-middle-platform/purchaseListPrice/v2/index?currentPage=1&pageSize=9999`,
    // url: process.env.VUE_APP_SCM_URL + `purchaseListPrice/v2/index?currentPage=1&pageSize=9999`,
    data
  });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
        return item.name;
      })
      cb(res)
    })
  }
  return result
};
export const getPriceOrderList = async (supplierCode, currency, factorySeason) => { 
  let res = await getPriceOrderList1(supplierCode, currency, factorySeason)
  let list = res.data.records.map(item => {
    return item.name;
  })
  let result = {
    data: list,
    code: res.code,
    message: res.message,
  };
  return result
}


/**
 * 执行采购——采购订单
 * 修改采购订单
 */
export const submitPurchaseOrder = (id) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorders/${id}`,
    data
  });
};

/**
 * 无调用
 * 执行采购——采购订单
 * 根据价单名称，物料编码和米数查出物料价格
 */
export const priceByMaterialCode = (priceListName, materialCode, meter) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchasepricelist/v1/queryPriceByMaterialCode?priceListName=${priceListName}&materialCode=${materialCode}&meter=${meter}`,
  });
};


/**
 * 执行采购——预收货清单管理
 * 工厂调拨单列表
 */
// export const requisitionList = (currentPage, pageSize, data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_SCM_URL + `actoryAllocation/v1/factoryallocations/search?currentPage=${currentPage}&pageSize=${pageSize}`,
//     data
//   });
// };
export const requisitionList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/searchByPre?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——预收货清单管理
 * 预收获清单列表
 */
export const prepareReceiptList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/queryPrePageByCg?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——预收货清单管理
 * 根据id查询工厂调拨单与工厂调拨项
 */
// export const searchRequisitionItem = (idList) => {
//   return http({
//     method: "get",
//     url: process.env.VUE_APP_SCM_URL + `factoryAllocationItem/v1/faitems?list=${idList}`
//   });
// };

/**
 * 执行采购——预收货清单管理
 * 创建预收货清单并添加选中的清单子项
 */
export const addRequisitionList = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/savePrepareReceiptList`,
    data
  });
};

/**
 * 获取供应商列表字典
 */
export const supplierDictList = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL + `supplier/v1/supplierCompanys/dict`
  });
};

/**
 * 删除预收货清单
 */
export const deletePrepareReceipt = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/removePrepareReceiptList/${id}`,
  });
};

/**
 * 编辑预收货清单
 */
export const editPrepareReceipt = (data) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/updatePrepareReceiptList`,
    data
  });
};

// 新增
/**
 * 审批流程调取
 */
export const approvalProcessInfo = (flowUuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/bpm/v1/approvalOpinions?flowUuid=${flowUuid}`
  });
};

/**
 * 常量查询数据字典
 */
export const constDictList = (dictIndex) => {
  if (dictIndex == 'VirtualInventory') {
    console.log('constDictList,VirtualInventory');
    let res ={}
    res.data = [
      {"name": "集团仓","index": "A0"},
      {"name": "零剪仓","index": "B0"},
      {"name": "职业装仓","index": "C0"},
      {"name": "订货仓","index": "D0" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'Currency') {
    console.log('constDictList,Currency');
    let res ={}
    res.data = [
      {"name": "人民币","index": "1"},
      {"name": "美元","index": "2"},
      {"name": "欧元","index": "3"},
      {"name": "英镑","index": "4"},
      {"name": "日元","index": "5"},
      {"name": "韩元","index": "6"},
      {"name": "港币","index": "7"}
  ]
    res.code = '0'
    return res    
  }
  if (dictIndex == 'CombinedMaterialClass') {
    console.log('constDictList,CombinedMaterialClass');
    let res ={}
    res.data = [
      {"name": "样本","index": "1"},
      {"name": "画册","index": "2"}
    ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'TransferType') {
    console.log('constDictList,TransferType');
    let res ={}
    res.data =  [
      {"name": "客订-工厂调拨","index": "A001"},
      {"name": "备货-工厂调拨","index": "A002"}
  ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'downPayment') {
    console.log('dictList,downPayment');
    let res ={}
    res.data =  [
      {
          "id": 162,
          "rev": 1,
          "createTime": "2020-05-25T19:23:32.203+0000",
          "updateTime": "2020-05-25T19:23:32.203+0000",
          "dictIndex": "downPayment",
          "dictItemValue": "30%",
          "dictItemName": "30%",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 163,
          "rev": 1,
          "createTime": "2020-05-25T19:24:10.650+0000",
          "updateTime": "2020-05-25T19:24:10.650+0000",
          "dictIndex": "downPayment",
          "dictItemValue": "100%",
          "dictItemName": "100%",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'ReservedType') {
    console.log('dictList,ReservedType');
    let res ={}
    res.data = [
      {
          "id": 126,
          "rev": 1,
          "createTime": "2020-02-27T14:41:01.000+0000",
          "updateTime": "2020-02-27T14:41:04.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A001",
          "dictItemName": "客订-面料",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 127,
          "rev": 1,
          "createTime": "2020-02-27T15:16:13.000+0000",
          "updateTime": "2020-02-27T15:16:16.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A002",
          "dictItemName": "客订-样本样布",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 128,
          "rev": 1,
          "createTime": "2020-02-27T15:17:56.000+0000",
          "updateTime": "2020-02-27T15:17:58.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A003",
          "dictItemName": "客订-画册样布",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 129,
          "rev": 1,
          "createTime": "2020-02-27T15:19:07.000+0000",
          "updateTime": "2020-02-27T15:19:09.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A004",
          "dictItemName": "客订-样衣样布",
          "dictItemOrder": 4,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 130,
          "rev": 1,
          "createTime": "2020-02-27T15:19:49.000+0000",
          "updateTime": "2020-02-27T15:19:52.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A005",
          "dictItemName": "客订-其他样布",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 131,
          "rev": 1,
          "createTime": "2020-02-27T15:20:35.000+0000",
          "updateTime": "2020-02-27T15:20:40.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B006",
          "dictItemName": "非客订-面料",
          "dictItemOrder": 6,
          "remark": "123123123",
          "dictItemLanguage": "CH"
      },
      {
          "id": 132,
          "rev": 1,
          "createTime": "2020-02-27T15:21:46.000+0000",
          "updateTime": "2020-02-27T15:21:48.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B007",
          "dictItemName": "非客订-画册样布",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 133,
          "rev": 1,
          "createTime": "2020-02-27T15:22:33.000+0000",
          "updateTime": "2020-02-27T15:22:44.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B008",
          "dictItemName": "非客订-样本样布",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 134,
          "rev": 1,
          "createTime": "2020-02-27T15:23:31.000+0000",
          "updateTime": "2020-02-27T15:23:33.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B009",
          "dictItemName": "非客订-其他样布",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 135,
          "rev": 1,
          "createTime": "2020-02-27T15:24:37.000+0000",
          "updateTime": "2020-02-27T15:24:39.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B010",
          "dictItemName": "非客订-样衣样布",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 136,
          "rev": 1,
          "createTime": "2020-02-27T15:25:08.000+0000",
          "updateTime": "2020-02-27T15:25:10.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "C011",
          "dictItemName": "其他",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'purchaseType') {
    console.log('dictList,purchaseType');
    let res ={}
    res.data =  [
      {
          "id": 623,
          "rev": 1,
          "createTime": "2021-01-13T03:26:59.147+0000",
          "updateTime": "2021-01-13T03:26:59.147+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B099999",
          "dictItemName": "世家宝-样本采购",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 147,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010201",
          "dictItemName": "以销定采-职业装",
          "dictItemOrder": 2,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 612,
          "rev": 1,
          "createTime": "2020-10-26T04:00:39.133+0000",
          "updateTime": "2020-10-26T04:00:39.133+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010206",
          "dictItemName": "以销定采-职业装-面料生产",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 149,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010401",
          "dictItemName": "以销定采-订货大样",
          "dictItemOrder": 4,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 140,
          "rev": 1,
          "createTime": "2020-10-19T03:42:17.000+0000",
          "updateTime": "2020-10-19T03:42:20.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010501",
          "dictItemName": "以销定采-订货大货",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 148,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010301",
          "dictItemName": "以销定采-零剪",
          "dictItemOrder": 6,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 171,
          "rev": 1,
          "createTime": "2020-05-31T23:43:34.614+0000",
          "updateTime": "2020-05-31T23:43:34.614+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010202",
          "dictItemName": "以销定采-零剪-叫料",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 611,
          "rev": 1,
          "createTime": "2020-10-26T03:59:33.314+0000",
          "updateTime": "2020-10-26T03:59:33.314+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010306",
          "dictItemName": "以销定采-零剪-面料生产",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 184,
          "rev": 1,
          "createTime": "2020-06-22T22:03:47.089+0000",
          "updateTime": "2020-06-22T22:03:47.089+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20305",
          "dictItemName": "备货采购-新首-职业装",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 185,
          "rev": 1,
          "createTime": "2020-06-22T22:04:27.451+0000",
          "updateTime": "2020-06-22T22:04:27.451+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20306",
          "dictItemName": "备货采购-新首补单-职业装",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 187,
          "rev": 1,
          "createTime": "2020-06-22T22:05:44.397+0000",
          "updateTime": "2020-06-22T22:05:44.397+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20308",
          "dictItemName": "备货采购-新首补单-零剪",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 186,
          "rev": 1,
          "createTime": "2020-06-22T22:05:05.803+0000",
          "updateTime": "2020-06-22T22:05:05.803+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20307",
          "dictItemName": "备货采购-新首-零剪",
          "dictItemOrder": 12,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 151,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020302",
          "dictItemName": "备货采购-职业装",
          "dictItemOrder": 13,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 153,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020203",
          "dictItemName": "备货采购-职业装-坯布/纱线",
          "dictItemOrder": 14,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 610,
          "rev": 1,
          "createTime": "2020-10-26T03:59:08.833+0000",
          "updateTime": "2020-10-26T03:59:08.833+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020206",
          "dictItemName": "备货采购-职业装-面料生产",
          "dictItemOrder": 15,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 150,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020301",
          "dictItemName": "备货采购-零剪",
          "dictItemOrder": 16,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 608,
          "rev": 1,
          "createTime": "2020-10-23T02:31:45.536+0000",
          "updateTime": "2020-10-23T02:31:45.536+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020304",
          "dictItemName": "备货采购-零剪-世家宝",
          "dictItemOrder": 17,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 154,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020303",
          "dictItemName": "备货采购-零剪-坯布/纱线",
          "dictItemOrder": 18,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 609,
          "rev": 1,
          "createTime": "2020-10-26T03:58:45.136+0000",
          "updateTime": "2020-10-26T03:58:45.136+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020306",
          "dictItemName": "备货采购-零剪-面料生产",
          "dictItemOrder": 19,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 637,
          "rev": 1,
          "createTime": "2021-02-01T10:42:11.309+0000",
          "updateTime": "2021-02-01T10:42:11.309+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020309",
          "dictItemName": "开发大样-零剪",
          "dictItemOrder": 20,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 644,
          "rev": 1,
          "createTime": "2021-10-29T06:57:46.888+0000",
          "updateTime": "2021-10-29T06:57:46.888+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020310",
          "dictItemName": "开发大样-职业装",
          "dictItemOrder": 21,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 197,
          "rev": 1,
          "createTime": "2020-10-13T02:28:40.000+0000",
          "updateTime": "2020-10-13T02:28:47.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020305",
          "dictItemName": "日常备货-集团",
          "dictItemOrder": 22,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 155,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B07",
          "dictItemName": "现货采购-原材料",
          "dictItemOrder": 23,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 201,
          "rev": 1,
          "createTime": "2020-08-18T04:29:52.942+0000",
          "updateTime": "2020-08-18T04:29:52.942+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B9901",
          "dictItemName": "采购申请差异单",
          "dictItemOrder": 24,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'SaleSeasou') {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "S25", "index": "S25" },
      { "name": "S26", "index": "S26" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" },
      { "name": "W25/26", "index": "W25/26" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'PurchaseOrderType') {
    console.log('dictList,PurchaseOrderType');
    let res ={}
    res.data =  [
      { name: "生产采购", index: "ProductionPurchase" },
      { name: "以销定采", index: "SalesPurchasing" },
      { name: "叫料采购", index: "CallPurchase" },
      { name: "订货采购-内贸大货", index: "OrderPurchase" },
      { name: "订货采购-内贸大样", index: "OrderBrokerBulkSamplePurchase" },
      { name: "原材料采购(成衣原料)", index: "RawMaterialPurchase" },
      { name: "内部往来订单", index: "InternalPurchase" },
      { name: "采购订单差异单", index: "DifferencePurchase" },
      { name: "大样", index: "Sample" },
      { name: "开发大样-零剪", index: "DevelopSample-Retail" },
      { name: "开发大样-职业装", index: "DevelopSampleSuits" },
      { name: "定织大样", index: "FixedWeaveSample" },
      { name: "船样", index: "ShipSample" },
      { name: "样包", index: "SampleBag" },
      { name: "新首-大货", index: "NewHeadBigGoods" },
      { name: "新首-大样", index: "NewHeadDetail" },
      { name: "日常备货-补单", index: "DailyStockSupplementOrder" },
      { name: "日常备货-含定织", index: "DailyStockFixedWeave" },
      { name: "待处理", index: "Pending" },
      { name: "特殊项目", index: "SpecialProjects" },
      { name: "预留询货", index: "ReservedInquiry" },
      { name: "配品", index: "Accessories" },
      { name: "原材料(坯布纱线)", index: "rawMaterial" },
      { name: "样本采购", index: "samplePurchase" }
    ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'TradeTerms') {
    console.log('dictList,TradeTerms');
    let res ={}
    res.data =  [
      {
          "name": "CIF",
          "index": "CIF"
      },
      {
          "name": "C&F",
          "index": "C&F"
      },
      {
          "name": "CIP",
          "index": "CIP"
      },
      {
          "name": "CFR",
          "index": "CFR"
      },
      {
          "name": "DAT",
          "index": "DAT"
      },
      {
          "name": "DAP",
          "index": "DAP"
      },
      {
          "name": "EXW",
          "index": "EXW"
      },
      {
          "name": "FCA",
          "index": "FCA"
      },
      {
          "name": "FOB",
          "index": "FOB"
      },
      {
          "name": "其他",
          "index": "OTHER"
      }
  ]
    res.code = '0'
    return  res
  }
  // TradeTerms
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=${dictIndex}`,
  // });
};

/**
 * 查询数据字典
 */
export const dictList = (dictIndex) => {
  if (dictIndex == 'VirtualInventory') {
    console.log('constDictList,VirtualInventory');
    let res ={}
    res.data = [
      {"name": "集团仓","index": "A0"},
      {"name": "零剪仓","index": "B0"},
      {"name": "职业装仓","index": "C0"},
      {"name": "订货仓","index": "D0" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'Currency') {
    console.log('constDictList,Currency');
    let res ={}
    res.data = [
      {"name": "人民币","index": "1"},
      {"name": "美元","index": "2"},
      {"name": "欧元","index": "3"},
      {"name": "英镑","index": "4"},
      {"name": "日元","index": "5"},
      {"name": "韩元","index": "6"},
      {"name": "港币","index": "7"}
  ]
    res.code = '0'
    return res    
  }
  if (dictIndex == 'CombinedMaterialClass') {
    console.log('constDictList,CombinedMaterialClass');
    let res ={}
    res.data = [
      {"name": "样本","index": "1"},
      {"name": "画册","index": "2"}
    ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'TransferType') {
    console.log('constDictList,TransferType');
    let res ={}
    res.data =  [
      {"name": "客订-工厂调拨","index": "A001"},
      {"name": "备货-工厂调拨","index": "A002"}
  ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'downPayment') {
    console.log('dictList,downPayment');
    let res ={}
    res.data =  [
      {
          "id": 162,
          "rev": 1,
          "createTime": "2020-05-25T19:23:32.203+0000",
          "updateTime": "2020-05-25T19:23:32.203+0000",
          "dictIndex": "downPayment",
          "dictItemValue": "30%",
          "dictItemName": "30%",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 163,
          "rev": 1,
          "createTime": "2020-05-25T19:24:10.650+0000",
          "updateTime": "2020-05-25T19:24:10.650+0000",
          "dictIndex": "downPayment",
          "dictItemValue": "100%",
          "dictItemName": "100%",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'ReservedType') {
    console.log('dictList,ReservedType');
    let res ={}
    res.data = [
      {
          "id": 126,
          "rev": 1,
          "createTime": "2020-02-27T14:41:01.000+0000",
          "updateTime": "2020-02-27T14:41:04.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A001",
          "dictItemName": "客订-面料",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 127,
          "rev": 1,
          "createTime": "2020-02-27T15:16:13.000+0000",
          "updateTime": "2020-02-27T15:16:16.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A002",
          "dictItemName": "客订-样本样布",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 128,
          "rev": 1,
          "createTime": "2020-02-27T15:17:56.000+0000",
          "updateTime": "2020-02-27T15:17:58.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A003",
          "dictItemName": "客订-画册样布",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 129,
          "rev": 1,
          "createTime": "2020-02-27T15:19:07.000+0000",
          "updateTime": "2020-02-27T15:19:09.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A004",
          "dictItemName": "客订-样衣样布",
          "dictItemOrder": 4,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 130,
          "rev": 1,
          "createTime": "2020-02-27T15:19:49.000+0000",
          "updateTime": "2020-02-27T15:19:52.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A005",
          "dictItemName": "客订-其他样布",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 131,
          "rev": 1,
          "createTime": "2020-02-27T15:20:35.000+0000",
          "updateTime": "2020-02-27T15:20:40.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B006",
          "dictItemName": "非客订-面料",
          "dictItemOrder": 6,
          "remark": "123123123",
          "dictItemLanguage": "CH"
      },
      {
          "id": 132,
          "rev": 1,
          "createTime": "2020-02-27T15:21:46.000+0000",
          "updateTime": "2020-02-27T15:21:48.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B007",
          "dictItemName": "非客订-画册样布",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 133,
          "rev": 1,
          "createTime": "2020-02-27T15:22:33.000+0000",
          "updateTime": "2020-02-27T15:22:44.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B008",
          "dictItemName": "非客订-样本样布",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 134,
          "rev": 1,
          "createTime": "2020-02-27T15:23:31.000+0000",
          "updateTime": "2020-02-27T15:23:33.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B009",
          "dictItemName": "非客订-其他样布",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 135,
          "rev": 1,
          "createTime": "2020-02-27T15:24:37.000+0000",
          "updateTime": "2020-02-27T15:24:39.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B010",
          "dictItemName": "非客订-样衣样布",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 136,
          "rev": 1,
          "createTime": "2020-02-27T15:25:08.000+0000",
          "updateTime": "2020-02-27T15:25:10.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "C011",
          "dictItemName": "其他",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'purchaseType') {
    console.log('dictList,purchaseType');
    let res ={}
    res.data =  [
      {
          "id": 623,
          "rev": 1,
          "createTime": "2021-01-13T03:26:59.147+0000",
          "updateTime": "2021-01-13T03:26:59.147+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B099999",
          "dictItemName": "世家宝-样本采购",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 147,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010201",
          "dictItemName": "以销定采-职业装",
          "dictItemOrder": 2,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 612,
          "rev": 1,
          "createTime": "2020-10-26T04:00:39.133+0000",
          "updateTime": "2020-10-26T04:00:39.133+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010206",
          "dictItemName": "以销定采-职业装-面料生产",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 149,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010401",
          "dictItemName": "以销定采-订货大样",
          "dictItemOrder": 4,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 140,
          "rev": 1,
          "createTime": "2020-10-19T03:42:17.000+0000",
          "updateTime": "2020-10-19T03:42:20.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010501",
          "dictItemName": "以销定采-订货大货",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 148,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010301",
          "dictItemName": "以销定采-零剪",
          "dictItemOrder": 6,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 171,
          "rev": 1,
          "createTime": "2020-05-31T23:43:34.614+0000",
          "updateTime": "2020-05-31T23:43:34.614+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010202",
          "dictItemName": "以销定采-零剪-叫料",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 611,
          "rev": 1,
          "createTime": "2020-10-26T03:59:33.314+0000",
          "updateTime": "2020-10-26T03:59:33.314+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010306",
          "dictItemName": "以销定采-零剪-面料生产",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 184,
          "rev": 1,
          "createTime": "2020-06-22T22:03:47.089+0000",
          "updateTime": "2020-06-22T22:03:47.089+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20305",
          "dictItemName": "备货采购-新首-职业装",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 185,
          "rev": 1,
          "createTime": "2020-06-22T22:04:27.451+0000",
          "updateTime": "2020-06-22T22:04:27.451+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20306",
          "dictItemName": "备货采购-新首补单-职业装",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 187,
          "rev": 1,
          "createTime": "2020-06-22T22:05:44.397+0000",
          "updateTime": "2020-06-22T22:05:44.397+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20308",
          "dictItemName": "备货采购-新首补单-零剪",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 186,
          "rev": 1,
          "createTime": "2020-06-22T22:05:05.803+0000",
          "updateTime": "2020-06-22T22:05:05.803+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20307",
          "dictItemName": "备货采购-新首-零剪",
          "dictItemOrder": 12,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 151,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020302",
          "dictItemName": "备货采购-职业装",
          "dictItemOrder": 13,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 153,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020203",
          "dictItemName": "备货采购-职业装-坯布/纱线",
          "dictItemOrder": 14,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 610,
          "rev": 1,
          "createTime": "2020-10-26T03:59:08.833+0000",
          "updateTime": "2020-10-26T03:59:08.833+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020206",
          "dictItemName": "备货采购-职业装-面料生产",
          "dictItemOrder": 15,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 150,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020301",
          "dictItemName": "备货采购-零剪",
          "dictItemOrder": 16,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 608,
          "rev": 1,
          "createTime": "2020-10-23T02:31:45.536+0000",
          "updateTime": "2020-10-23T02:31:45.536+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020304",
          "dictItemName": "备货采购-零剪-世家宝",
          "dictItemOrder": 17,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 154,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020303",
          "dictItemName": "备货采购-零剪-坯布/纱线",
          "dictItemOrder": 18,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 609,
          "rev": 1,
          "createTime": "2020-10-26T03:58:45.136+0000",
          "updateTime": "2020-10-26T03:58:45.136+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020306",
          "dictItemName": "备货采购-零剪-面料生产",
          "dictItemOrder": 19,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 637,
          "rev": 1,
          "createTime": "2021-02-01T10:42:11.309+0000",
          "updateTime": "2021-02-01T10:42:11.309+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020309",
          "dictItemName": "开发大样-零剪",
          "dictItemOrder": 20,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 644,
          "rev": 1,
          "createTime": "2021-10-29T06:57:46.888+0000",
          "updateTime": "2021-10-29T06:57:46.888+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020310",
          "dictItemName": "开发大样-职业装",
          "dictItemOrder": 21,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 197,
          "rev": 1,
          "createTime": "2020-10-13T02:28:40.000+0000",
          "updateTime": "2020-10-13T02:28:47.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020305",
          "dictItemName": "日常备货-集团",
          "dictItemOrder": 22,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 155,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B07",
          "dictItemName": "现货采购-原材料",
          "dictItemOrder": 23,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 201,
          "rev": 1,
          "createTime": "2020-08-18T04:29:52.942+0000",
          "updateTime": "2020-08-18T04:29:52.942+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B9901",
          "dictItemName": "采购申请差异单",
          "dictItemOrder": 24,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'SaleSeasou') {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "S26", "index": "S26" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'PurchaseOrderType') {
    console.log('dictList,PurchaseOrderType');
    let res ={}
    res.data =  [
      { name: "生产采购", index: "ProductionPurchase" },
      { name: "以销定采", index: "SalesPurchasing" },
      { name: "叫料采购", index: "CallPurchase" },
      { name: "订货采购-内贸大货", index: "OrderPurchase" },
      { name: "订货采购-内贸大样", index: "OrderBrokerBulkSamplePurchase" },
      { name: "原材料采购(成衣原料)", index: "RawMaterialPurchase" },
      { name: "内部往来订单", index: "InternalPurchase" },
      { name: "采购订单差异单", index: "DifferencePurchase" },
      { name: "大样", index: "Sample" },
      { name: "开发大样-零剪", index: "DevelopSample-Retail" },
      { name: "开发大样-职业装", index: "DevelopSampleSuits" },
      { name: "定织大样", index: "FixedWeaveSample" },
      { name: "船样", index: "ShipSample" },
      { name: "样包", index: "SampleBag" },
      { name: "新首-大货", index: "NewHeadBigGoods" },
      { name: "新首-大样", index: "NewHeadDetail" },
      { name: "日常备货-补单", index: "DailyStockSupplementOrder" },
      { name: "日常备货-含定织", index: "DailyStockFixedWeave" },
      { name: "待处理", index: "Pending" },
      { name: "特殊项目", index: "SpecialProjects" },
      { name: "预留询货", index: "ReservedInquiry" },
      { name: "配品", index: "Accessories" },
      { name: "原材料(坯布纱线)", index: "rawMaterial" },
      { name: "样本采购", index: "samplePurchase" }
    ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'TradeTerms') {
    console.log('dictList,TradeTerms');
    let res ={}
    res.data =  [
      {
          "name": "CIF",
          "index": "CIF"
      },
      {
          "name": "C&F",
          "index": "C&F"
      },
      {
          "name": "CIP",
          "index": "CIP"
      },
      {
          "name": "CFR",
          "index": "CFR"
      },
      {
          "name": "DAT",
          "index": "DAT"
      },
      {
          "name": "DAP",
          "index": "DAP"
      },
      {
          "name": "EXW",
          "index": "EXW"
      },
      {
          "name": "FCA",
          "index": "FCA"
      },
      {
          "name": "FOB",
          "index": "FOB"
      },
      {
          "name": "其他",
          "index": "OTHER"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'paymentMethod') {
    console.log('dictList,paymentMethod');
    let res ={}
    res.data = [
      {
          "id": 160,
          "rev": 1,
          "createTime": "2020-05-25T18:49:20.420+0000",
          "updateTime": "2020-05-25T18:49:20.420+0000",
          "dictIndex": "paymentMethod",
          "dictItemValue": "downPayment",
          "dictItemName": "预付",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 161,
          "rev": 1,
          "createTime": "2020-05-25T18:49:48.997+0000",
          "updateTime": "2020-05-25T18:49:48.997+0000",
          "dictIndex": "paymentMethod",
          "dictItemValue": "period",
          "dictItemName": "账期",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 613,
          "rev": 1,
          "createTime": "2020-11-11T06:36:37.593+0000",
          "updateTime": "2020-11-11T06:36:37.593+0000",
          "dictIndex": "paymentMethod",
          "dictItemValue": "None",
          "dictItemName": "无",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return  res
  }
  if (dictIndex == 'period') {
    console.log('constDictList,period');
    let res ={}
    res.data = [
      {
          "id": 164,
          "rev": 1,
          "createTime": "2020-05-25T19:25:37.041+0000",
          "updateTime": "2020-05-25T19:25:37.041+0000",
          "dictIndex": "period",
          "dictItemValue": "PayAtSight",
          "dictItemName": "见票即付",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 165,
          "rev": 1,
          "createTime": "2020-05-25T19:26:32.647+0000",
          "updateTime": "2020-05-25T19:26:32.647+0000",
          "dictIndex": "period",
          "dictItemValue": "MonthlyAccountPeriod",
          "dictItemName": "隔月账期",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 166,
          "rev": 1,
          "createTime": "2020-05-25T19:26:50.523+0000",
          "updateTime": "2020-05-25T19:26:50.523+0000",
          "dictIndex": "period",
          "dictItemValue": "30",
          "dictItemName": "30",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 167,
          "rev": 1,
          "createTime": "2020-05-25T19:26:57.157+0000",
          "updateTime": "2020-05-25T19:26:57.157+0000",
          "dictIndex": "period",
          "dictItemValue": "45",
          "dictItemName": "45",
          "dictItemOrder": 4,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 168,
          "rev": 1,
          "createTime": "2020-05-25T19:27:11.600+0000",
          "updateTime": "2020-05-25T19:27:11.600+0000",
          "dictIndex": "period",
          "dictItemValue": "60",
          "dictItemName": "60",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 169,
          "rev": 1,
          "createTime": "2020-05-25T19:27:19.316+0000",
          "updateTime": "2020-05-25T19:27:19.316+0000",
          "dictIndex": "period",
          "dictItemValue": "90",
          "dictItemName": "90",
          "dictItemOrder": 6,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 170,
          "rev": 1,
          "createTime": "2020-05-25T19:27:38.663+0000",
          "updateTime": "2020-05-25T19:27:38.663+0000",
          "dictIndex": "period",
          "dictItemValue": "180",
          "dictItemName": "180",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return res
  }
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dicts?dictIndex=${dictIndex}`,
  // });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 分页筛选展示预收货清单和清单项列表
 */
// export const advanceReceiptList = (currentPage, pageSize, data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/queryPreAndItemList?currentPage=${currentPage}&pageSize=${pageSize}`,
//     data
//   });
// };
export const advanceReceiptList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/queryPreAndBatchs?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 保存或更新预收货清单以及子项
 */
export const editAdvanceReceipt = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/savePrepareReceiptList`,
    data
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 更新预收货清单审批信息
 */
export const submitPrepareReceipt = (data) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/submitPrepareReceiptList`,
    data
  });
};

/**
 * 执行采购——预收货清单管理
 * 展示预收货清单项的详细批次信息
 */
export const batchNumberFallInfo = (itemUuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlistitem/v1/queryPreItemBatch?itemUuid=${itemUuid}`
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 查询预收货清单父项和批次信息，根据清单id
 */
export const batchNumberList = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/queryPreById/${id}`
  });
};

/**
 * 执行采购——预收货清单管理
 * 选中调拨单子项，拆成单包数据组成预收货批次创建列表
 */
export const searchRequisitionItem = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/faItemConvertToPreBatch`,
    data
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 查询物料的供应商信息
 */
export const findmaterialCode = (materialCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `material/v1/materials/queryMaterialSupplier?materialCode=${materialCode}`
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 根据id删除一个附件
 */
export const deleteUploadFile = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/attachments/${id}`,
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 根据uuid和附件类型，查询所有的附件
 */
export const searchUploadFiles = (uuid, type) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/attachments/search?uuid=${uuid}&type=${type}`,
  });
};

/**
 * New
 * 执行采购——预收货清单管理
 * 更新预收货清单上传箱单数量
 */
export const updatePackingQuantity = (prepareReceiptCode, quantity) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/updatePackingListQuantity?prepareReceiptCode=${prepareReceiptCode}&quantity=${quantity}`,
  });
};

/**
 * 执行采购——首付款申请
 * 分页查询首付款申请单
 */
export const downPaymentList= (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/downpayments/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——首付款申请
 * 新增首付款申请单
 */
export const addDownPayment= (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/downpayments`,
    data
  });
};

/**
 * 执行采购——首付款申请
 * 删除首付款申请单的一行
 */
export const deleteDownPayment = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/downpayments/${id}`,
  });
};

/**
 * 执行采购——首付款申请
 * 提交首付款单到BPM
 */
export const submitDownPayment = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/submitdownpaymenttobpm?id=${id}`
  });
};

/**
 * 执行采购——首付款申请
 * 是否可以发起首付款
 */
export const initDownPayment = (purchaseOrderId) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/submitornot?purchaseOrderId=${purchaseOrderId}`
  });
};

/**
 * 获取uuid
 */
export const getUUID = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/getUUID`,
  });
};

/**
 * 执行采购——首付款申请
 * 根据id查询首付款申请单
 */
export const searchDownPayment = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `downpayment/v1/downpayments/${id}`
  });
};

/**
   *根据物料编号查询计量单位
   */
export const getmaterialCodeUnit=(materialCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_MATERIAL_URL+ `material/v1/materials/purchasemeasurementunit?materialCode=${materialCode}`
  });
};

/**
 * 执行采购——预收货清单
 * 根据uuid集合下载选中调拨单项
 */
export const exportExcel = (uuids) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/exportPhpExcel?uuids=${uuids}`
  });
};
/**
 * 执行采购——预收货清单
 * 根据发票号查询发票详情和箱单列表
 */
export const factoryInvoice = (factoryInvoice) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `factoryInvoice/v1/queryInvoiceAndPackingList/${factoryInvoice}`
  });
};

/**
 * NEW
 * 执行采购——采购请求
 * 根据uuid查询采购申请项
 */
export const searchPurchaseRequest = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems/getPrisByUuids`,
    data
  });
};

/**
 * 执行采购——货妥管理
 * 根据工厂确认书号查询采购订单号批量
 */
export const searchPOCodeByConfirmNumber = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/searchPONumberByCodeFetch`,
    data
  });
};

/**
 * 执行采购——货妥管理
 * 根据PO号和物料集合查询明细
 */
export const searchPOItemByPOAndMaterial = (poId, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaseorderitem/v1/searchPOItemByPOAndMaterial?poId=${poId}`,
    data
  });
};

/**
 * 执行采购——工厂确认
 * 根据确认书号和物料号查询PO单号和PO行号
 */
export const searchPOInfoByPOAndCN = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/searchConfirmNumberByPoAndConfirm`,
    data
  });
};

/**
 * 执行采购——工厂确认
 * 查出采购订单和订单下所有货妥清单
 */
export const searchCargoReadyByPO = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/queryOrderCargoReadyList`,
    data
  });
};

/**
 * 执行采购——工厂确认
 * 批量货妥查询专用
 */
export const fetchFactoryConfirm = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/selectByConfirmPOLineNum`,
    data
  });
};

/**
 * 根据物料号，确认书，规格查询已确认，未妥完的po行
 */
export const fetchNotReadyPO = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/selectNoReadyPo`,
    data
  });
};

/**
 * 撤销采购订单子项
 */
export const revokePurchaseOrderItem = (uuid,cancelRemark) => {
  return http({
    method: "get",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/cancelPurchaseOrderItem?uuid=${uuid}&cancelRemark=${cancelRemark}`,
  });
};

/**
 * 执行采购——首付款申请
 * 首付款发起
 */
export const purchaseOrderConfirmList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaseorder/v1/purchaseorderAndItems/searchsearchForFactoryConfirm?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 执行采购——采购请求
 * 采购删除审批通过PR子项（方便采购人员查看）
 */
export const purchaseDeletePri = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseDeletePri?uuid=${uuid}`
  });
};

/**
 * 执行采购——采购订单
 * 批量查询价单价格
 */
export const batchPriceByMaterialCode = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_URL + `finance-middle-platform/purchasepricelist/v2/batchQueryPrice`,
    data
  });
};
/**
 * 新增或修改
 */
export const addOrUpdate = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preCourier/v1/addOrUpdate`,
    data
  });
};

/**
 * 货妥批量删除
 */
export const getBatchDeletion = (purchaseOrderId) => {
  return http({
    method: "delete",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/removeReadylistByPoid?purchaseOrderId=${purchaseOrderId}`
  });
};

/**
 * 预收货清单——调拨单列表
 * 分解调拨行
 */
 export const getSplitAllocation = (factoryAllocationCode,lineNum,quantity) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/splitAllocation?factoryAllocationCode=${factoryAllocationCode}&lineNum=${lineNum}&quantity=${quantity}`,
  });
};
